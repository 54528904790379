
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasic from "@/views/resources/documentation/element-ui/navigation/page-header/Basic.vue";

export default defineComponent({
  name: "page-header",
  components: {
    EUIBasic
  },
  setup() {
    setCurrentPageTitle("PageHeader");
  }
});
